.table{
  .switch input:checked + label span:before {
    left: -40px;
  }

  .switch input + label span:before {
    left: -27px;
  }
  tbody tr td:first-of-type, thead tr th:first-of-type{
    padding-left: 10px;
  }
  td, th{
    padding: .8rem;
  }
  tr{
    td{
      font-size: 13px;
      vertical-align: middle;
      &:last-of-type{
        text-align: right;
        > a , > form, > button{
          margin-left: 12%;
        }
      }
    }
    &:hover{
      td{
        background-color: $yellow-light;
      }
    }
    th{
      padding: .5rem;
    }
  }
  thead{
    th {
      &.grid-small {
        width: 70px;
      }

      &.grid-medium {
        width: 132px;
      }
    }
    .b-actions{
      width: 170px;
    }
    .form-group{
      margin-bottom: 0;
    }
    a:not(.btn){
      color: #000;
    }

    .sortable{
      > a{
        margin-bottom: 9px;
        display: inline-block;
      }
    }
    .sortable > a, legend{
      @extend .font-AvenirHeavy;
      font-size: calculateRem(14px);
      i.fa{
        color: $blue;
        font-size: 1rem;
      }
      .fa-filter{
        font-size: 1rem;
      }
    }
    [class="sortable sylius-table-column-wine_bottle.format"], [class="sortable sylius-table-column-place_bottle_view.buyingPrice"]{
      > a{
        margin-bottom: 0;
      }
    }
    .modal-filter{
      .filter-option {
        padding: 4px 10px;
        cursor: pointer;
        font-family: $font-AvenirRoman !important;
        &:hover{
          background-color: $primary;
        }
      }
      [selected="selected"], .active{
        background-color: $yellow-light;
      }
    }
    select.form-control, input.form-control{
      @extend .form-control-sm;
      padding: 0 .2rem !important;
      border-radius: 0;
      border-color: #ccd1d9;
    }
    select.form-control{
      padding: .1rem .8rem;
    }
    input.form-control{
      margin-top: 2px;
    }

  }
  tbody{
    .action-row i{
      font-size: 20px;
      color: #cccccc;
    }
    .dropdown-toggle{
      cursor: pointer;
      &:after{
        display: none;
      }
    }
    .dropdown-menu{
      top: -1px;
      left: 15px !important;
      box-shadow: -1px -1px 10px -2px rgba(0,0,0,.5);
      a.dropdown-item, button.dropdown-item{
        @extend .font-AvenirMedium;
        padding: 0.25rem .7rem;
        i{
          width: 25px;
          color: #000;
          font-size: 17px;
          text-align: center;
        }
      }
    }
  }
  &-cave{
    thead{
      th.sortable{
        &:first-of-type{
          width: 417px;
        }
      }
      .b-actions{
        width: 95px;
      }
    }
    tbody{
      tr{
          td:first-of-type{
            overflow: hidden;
            line-height: 16px;
            max-width: 111px;
          }

      }
    }
  }
}


@supports (-webkit-marquee-repetition:infinite) and (object-fit:fill) {

  #modules-form{
    .switch{
      .switch input + label span:before{
        left: 18px;
      }
      input:checked + label span:before{
        left: 4px;
      }
      label.form-check-label{
        width: 100px;
        text-align: right;
      }
    }
  }
}
