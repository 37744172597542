
//
// These variables can be used to customize the switch component.
//
$custom-control-description-disabled-color: #9a9a9a;
$switch-height: 20px !default;
$switch-height-sm: 18px !default;
$switch-height-lg: calc(#{$input-height-lg} * .8) !default;
$switch-border-radius: $switch-height !default;
$switch-bg: #9a9a9a !default;
$switch-checked-bg: $success !default;
$switch-disabled-bg: $custom-control-indicator-disabled-bg !default;
$switch-disabled-color: $custom-control-description-disabled-color !default;
$switch-thumb-bg: $white !default;
$switch-thumb-border-radius: 50% !default;
$switch-thumb-padding: 2px !default;
$switch-focus-box-shadow: 0 0 0;
$switch-transition: .2s all !default;


.switch {
  padding: 5px 0;
  font-size: $font-size-base;
  position: relative;
  .form-check{
    padding-left: 0;
  }
  input {
    position: absolute;
    height: 1px;
    width: 1px;
    background: none;
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    overflow: hidden;
    padding: 0;

    + label {
      position: relative;
      min-width: calc(#{$switch-height} * 2);
      border-radius: $switch-border-radius;
      height: $switch-height;
      line-height: $switch-height;
      display: inline-block;
      cursor: pointer;
      outline: none;
      user-select: none;
      vertical-align: middle;
      text-indent: calc(calc(#{$switch-height} * 2) + .5rem);
    }

    + label::before,
    + label::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 30px;
      bottom: 0;
      display: block;
    }

    + label::before {
      right: 0;
      background-color: $switch-bg;
      border-radius: $switch-border-radius;
      transition: $switch-transition;
    }
    + label::after {
      top: $switch-thumb-padding;
      left: $switch-thumb-padding;
      width: 15px;
      height: 15px;
      border-radius: $switch-thumb-border-radius;
      background-color: $switch-thumb-bg;
      transition: $switch-transition;
    }

    + label span {
      &:before {
        font-size: 8px;
        position: absolute;
        top: 1px;
        left: -27px;
        display: block;
        content: 'OFF';
        color: #fff;
        z-index: 1;
      }
    }
    &:checked + label::before {
      background-color: $switch-checked-bg;
    }

    &:checked + label span {
      &:before {
        left: -40px;
        content: 'ON';
      }
    }

    &:checked + label::after {
      margin-left: $switch-height;
    }

    &:focus + label::before {
      outline: none;
      box-shadow: $switch-focus-box-shadow;
    }

    &:disabled + label {
      color: $switch-disabled-color;
      cursor: not-allowed;
    }

    &:disabled + label::before {
      background-color: $switch-disabled-bg;
    }

  }

  &-fake {
    display: block;
    height: 22px;
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(#{$switch-height} * 2);
      bottom: 0;
      display: block;
    }

    &::before {
      height: 20px;
      right: 0;
      background-color: $switch-bg;
      border-radius: $switch-border-radius;
      transition: $switch-transition;
    }

    &::after {
      top: $switch-thumb-padding;
      left: $switch-thumb-padding;
      width: calc(#{$switch-height} - calc(#{$switch-thumb-padding} * 2));
      height: calc(#{$switch-height} - calc(#{$switch-thumb-padding} * 2));
      border-radius: $switch-thumb-border-radius;
      background-color: $switch-thumb-bg;
      transition: $switch-transition;
    }

    .switch-label {
      &:before {
        font-size: 8px;
        position: absolute;
        top: 7px;
        left: 20px;
        content: 'OFF';
        display: block;
        color: #fff;
      }
    }

    &--true {
      &::before {
        background-color: $success;
      }

      &::before {
        background-color: $switch-checked-bg;
      }

      &::after {
        margin-left: $switch-height;
      }

      .switch-label {
        &:before {
          content: 'ON';
          left: 6px;
        }
      }
    }
  }

  // Small variation
  &.switch-sm {
    font-size: $font-size-sm;

    input {
      + label {
        min-width: calc(#{$switch-height-sm} * 2);
        height: $switch-height-sm;
        line-height: $switch-height-sm;
        text-indent: calc(calc(#{$switch-height-sm} * 2) + .5rem);
      }

      + label::before {
        width: calc(#{$switch-height-sm} * 2);
      }

      + label::after {
        width: calc(#{$switch-height-sm} - calc(#{$switch-thumb-padding} * 2));
        height: calc(#{$switch-height-sm} - calc(#{$switch-thumb-padding} * 2));
      }

      &:checked + label::after {
        margin-left: $switch-height-sm;
      }
    }
  }

  // Large variation
  &.switch-lg {
    font-size: $font-size-lg;

    input {
      + label {
        min-width: calc(#{$switch-height-lg} * 2);
        height: $switch-height-lg;
        line-height: $switch-height-lg;
        text-indent: calc(calc(#{$switch-height-lg} * 2) + .5rem);
      }

      + label::before {
        width: calc(#{$switch-height-lg} * 2);
      }

      + label::after {
        width: calc(#{$switch-height-lg} - calc(#{$switch-thumb-padding} * 2));
        height: calc(#{$switch-height-lg} - calc(#{$switch-thumb-padding} * 2));
      }

      &:checked + label::after {
        margin-left: $switch-height-lg;
      }
    }
  }

  + .switch {
    margin-left: 1rem;
  }
}

//form control
select.form-control {
  padding: 0;
}

//input
.input-group-text {
  padding-left: 0.4rem;
}

//radio
.radio{
  margin-left: 14px;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
}
.checkround {
  &:before{
    content: '';
    position: absolute;
    top: -4px;
    left: 6px;
    height: 20px;
    width: 20px;
    background-color: #fff ;
    border-color:$primary;
    border-style:solid;
    border-width:2px;
    border-radius: 50%;
  }

}

/* When the radio button is checked, add a blue background */
.radio input:checked ~ .checkround {
  background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkround:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio input:checked ~ .checkround:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio .checkround:after {
  top: 0;
  left: 10px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: $primary;
}

/* The check */
.check {
  margin-left: 14px;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.check input:checked ~ .checkmark {
  &:after {
    display: block;
    top: 0;
    left: 10px;
    width: 12px;
    height: 12px;
    background: $primary;
  }
}
/* Create a custom checkbox */
.checkmark {
  &:before {
    content: '';
    position: absolute;
    top: -4px;
    left: 6px;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-color: $primary;
    border-style: solid;
    border-width: 2px;
  }
}


.col-form-label-lg {
  font-size: calculateRem(16px);
  margin-bottom: 0;
  padding-bottom: 0;
}

.form-control-lg {
  padding: 3px .4rem 0;
  font-size: calculateRem(18px);
  height: 24px;
}

.input-title {
  font-size: calculateRem(15px);
  height: 30px;
}

.form-control-label--small {
  color: #868686;
  margin-left: 10px;
  font-size: calculateRem(11px);
}

.form{
  &-group{
    label{
      &.required{
        position: relative;
        &:after{
          content: "*";
          color: $red;

        }
      }
    }
  }
}

/* Safari 9.0-10.0 (not 10.1) (non-iOS) */

_:-webkit-full-screen:not(:root:root) {
  .switch{
    input {
      + label span {
        &:before {
          font-size: 8px;
          position: absolute;
          top: 1px;
          left: 18px!important;
          display: block;
          content: 'OFF';
          color: #fff;
          z-index: 1;
        }
      }
      
      &:checked + label span {
        &:before {
          left: 5px!important;
        }
      }
    }
  }
}

.switch input+label[for="sylius_company_places_0_periodicSaving"]::before {
  width: 40px;
}
