body {
  background-color: #ccd0d9;
}

b {
  @extend .font-AvenirHeavy;
}

@for $i from 1 through 6 {
  .h#{$i}, h#{$i} {
    margin-bottom: calculateRem(4px);
    color: #000;
    @extend .AvenirLTPro-Black;
  }
}

.m-portlet__head-text {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.logo {
  @include media-breakpoint-down(lg) {
    float: left;
  }
  @include media-breakpoint-up(xl) {
    position: absolute;
    top: 6px;
  }
}

.triangle {
  position: absolute;

  &-right {
    top: -26px;
    right: 5px;
  }

  &-left {
    top: -26px;
    left: 5px;
  }

  &-with-shadow {
    width: 33px;
    height: 26px;
    position: relative;
    overflow: hidden;

    &:after {
      position: absolute;
      top: 18px;
      left: 6px;
      width: 16px;
      height: 16px;
      content: '';
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      background: #fff;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, .37);
    }
  }
}

.main {
  &-container {
    margin-top: 20px;

    &--content {
      background-color: #fff;
      -moz-box-shadow: 0px -5px 0px 0px $primary;
      -webkit-box-shadow: 0px -5px 0px 0px $primary;
      -o-box-shadow: 0px -5px 0px 0px $primary;
      box-shadow: 0px -5px 0px 0px $primary;
      filter: progid:DXImageTransform.Microsoft.Shadow(color=$primary, Direction=90, Strength=0);
      -moz-border-radius: 5px;
      -webkit-border-radius: 5px;
      border-radius: 5px;
    }

    &--subheader {
      h2 {
        font-size: 1rem;
      }

      &-breadcrumb {
        ul {
          padding-left: 0;
          list-style: none;

          li {
            display: inline-block;

            a {
              color: #434a54;

              b {
                font-size: calculateRem(16px);
                @extend .font-AvenirHeavy;
              }
            }

            &.breadcrumb-item + .breadcrumb-item::before {
              display: inline-block;
              padding-right: .5rem;
              content: '|';
              color: #6c757d;
            }
          }
        }

        min-width: 274px;
      }

      &-actions {
        .btn-lg.btn-primary {
          padding: 10px 16px;
        }

        a {
          i {
            vertical-align: text-bottom;
            font-size: 17px;
          }

          border-bottom-right-radius: 0;
        }
      }

      &-filters {
        min-height: 60px;

        .filter-item:not(:last-child) {
          margin-right: 11px;
        }
      }
    }
  }

  &-nav {
    &--menu {
      &--w {
        @include media-breakpoint-down(lg) {
          float: left;
          width: 85%;
        }
        @include media-breakpoint-up(xl) {
          width: 100%;
        }
      }

      .navbar-nav {
        @include media-breakpoint-down(lg) {
          display: block;
        }

        .nav-item {
          text-align: center;
          position: relative;
          @include media-breakpoint-down(lg) {
            display: inline-block;
            padding: 11px
          }

          .dropdown-menu {
            margin: 0 0 0 !important;
            background-color: #CCD0D9;
            border-left: 2px solid #facb33;
            border-right: 2px solid #facb33;
            box-shadow: 0 4px 0 #facb33;
            @include media-breakpoint-down(lg) {
              position: absolute;
            }
          }

          &:hover > .dropdown-menu:not(:empty) {
            display: block;
          }

          &.show, &:hover {
            background-color: #ccd0d9;

            .nav-link {
              color: #48494d;

              &:before {
                display: block;
                position: absolute;
                top: 0;
                content: '';
                height: 10px;
                width: 100%;
                left: 0;
                background-color: $primary;
              }
            }
          }

          .nav-link {
            font-size: calculateRem(15px);

            &::after {
              display: none;
            }
          }
        }

      }

      .navbar-toggler {
        position: absolute;
        right: 0;
      }

      &-primary {
        @media (min-width: 1400px) {
          margin-left: 120px;
        }
        @media (min-width: 1500px) {
          margin-left: 153px !important;
        }
        &--link {
          @media (min-width: 1500px) {
            padding-left: 1rem !important;
            padding-right: 1rem !important;
          }

          //@media (min-width: 1600px) {
          //  padding-left: 1.85rem !important;
          //  padding-right: 1.85rem !important;
          //}
        }
      }
    }
    &--user {
      .nav-link {
        padding-right: .65rem !important;
        padding-left: .65rem !important;
      }
    }

    .form-search {
      width: 234px;
    }
  }
}

.footer {
  &-form {
    &__actions {
      margin-top: 40px;

      .btn {
        margin-left: 15px;
      }
    }
  }
}

.modal-grid {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 13px;
  background-color: #fff;
  position: absolute;
  border-radius: 3px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.37);

  &--hover {
    &:hover {
      > span {
        color: #0e6a7b;
        font-family: $font-AvenirHeavy;
      }

      > div {
        display: block;
        opacity: 1;
      }
    }
  }

  &--item {
    padding: 4px 10px;
    @extend .font-AvenirMedium;

    &.disabled {
      color: $gray-500;
    }
  }

  &--qty {
    display: none;
    opacity: 0;
    margin-top: 6px;
    margin-left: -5px;
  }
}

#alert {
  a {
    color: $danger;
    @extend .p-2;
    display: block;
  }
}

.modal {
  button.close {
    @include media-breakpoint-up(lg) {
      left: -30px;
    }
    height: 30px;
    position: absolute;
    z-index: 41;
    background: white;
    width: 30px;
    opacity: 1;
    top: 18px;
    color: #c00000;
  }
}


