@each $color, $value in $theme-colors {
  .badge-#{$color} {
    &.badge-bold {
      @extend .font-AvenirMedium;
      border: 2px solid $value;
    }
    &:not(.badge-bold) {
      @extend .font-AvenirLight;
      color: #000 !important;
      border: 2px solid $value;
      background: white;

      &:hover {
        background-color: $value !important;
      }
    }
  }
}

a.badge {
  cursor: pointer;

  &.disabled {
    border-color: white;
    opacity: .5;

    &:hover {
      opacity: 1;
    }
  }
}

.badge-secondary {
  &:hover {
    color: #fff !important;
  }
}
