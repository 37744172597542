// Fonts
//
// Font, line-height, and color for body text, headings, and more.
$body-bg:                   #ccd0d9 !default;
$body-color:                #000 !default;
$bg-light400: #E8E8E8;
// stylelint-disable value-keyword-case
$font-family-sans-serif: AvenirLTPro-Light, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:            $font-family-sans-serif;

$font-size-base:              0.875rem; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25 !default;
$font-size-sm:                $font-size-base * .875 !default;


$h1-font-size:                16px !default;
$h2-font-size:                15px !default;
$h3-font-size:                14px !default;
$h4-font-size:                13px !default;
$h5-font-size:                12px !default;
$h6-font-size:                12px !default;
// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system
$line-height-lg:              1.5 !default;
$line-height-sm:              1.5 !default;
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #9a9a9a;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;
$yellow-light: #fef2cc;
$grays: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
                (
                        "100": $gray-100,
                        "200": $gray-200,
                        "300": $gray-300,
                        "400": $gray-400,
                        "500": $gray-500,
                        "600": $gray-600,
                        "700": $gray-700,
                        "800": $gray-800,
                        "900": $gray-900
                ),
                $grays
);

$blue:    #1579e6;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #e3000f;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #28a745;
$teal:    #20c997;
$cyan:    #17a2b8;

$colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
                (
                        "blue":       $blue,
                        "indigo":     $indigo,
                        "purple":     $purple,
                        "pink":       $pink,
                        "red":        $red,
                        "orange":     $orange,
                        "yellow":     $yellow,
                        "green":      $green,
                        "teal":       $teal,
                        "cyan":       $cyan,
                        "white":      $white,
                        "gray":       $gray-600,
                        "gray-dark":  $gray-800
                ),
                $colors
);


$primary:       #facb33;
$secondary:     #000;
$success:       #1fa406;
$info:          $cyan;
$warning: #fa810a;
$danger:        $red;
$light:         $gray-100;
$dark:          #434a54;


$border-radius:               .25rem !default;
$border-radius-lg:            .3rem !default;
$border-radius-sm:            .2rem !default;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1400px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1377px
);

$link-color: #2b6882;
$link-hover-decoration:                   none !default;

$border-width:                1px !default;
$input-btn-border-width:      $border-width !default;
$input-border-width:                    $input-btn-border-width !default;
$line-height-base:            1;
$input-btn-line-height:                 $line-height-base;
$input-line-height:                     $input-btn-line-height;
$input-height-border:                   $input-border-width * 2 !default;
$input-padding-y:                       0.27rem;
$input-padding-x:                       0.8rem;
$input-height-inner:                    calc(#{$input-line-height * 1em} + #{$input-padding-y * 2}) !default;
$input-height-inner-half:               calc(#{$input-line-height * .5em} + #{$input-padding-y}) !default;
$input-height-inner-quarter:            calc(#{$input-line-height * .25em} + #{$input-padding-y / 2}) !default;

$input-height:                          calc(#{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border}) !default;
$font-weight-base: 300;
//$input-font-family:                     $input-btn-font-family !default;
//$input-font-size:                       $input-btn-font-size !default;
//$input-font-weight:                     $font-weight-base !default;
//$input-line-height:                     $input-btn-line-height !default;

$input-border-color: #828282 !default;
$input-color: #000000 !default;
$input-group-addon-color:               $input-color !default;
$input-group-addon-bg:                  #fff !default;
$input-group-addon-border-color:        #fff !default;

$input-btn-padding-y-sm:       0.3rem;
$input-btn-padding-x-sm:       0.8rem;
$input-btn-font-size-sm:      $font-size-sm !default;
$input-btn-line-height-sm:    .6 !default;



//padding: 1.09rem 1rem;
$nav-link-padding-y:                1.09rem !default;
$nav-link-padding-x:                1rem !default;

$white : #fff;

$navbar-nav-link-padding-x:         .5rem !default;
$navbar-dark-color:                 rgba($white, 1) !default;
$navbar-dark-hover-color:           rgba($white, 1) !default;
$navbar-dark-active-color:          $white !default;
$navbar-dark-disabled-color:        rgba($white, .25) !default;
$navbar-dark-toggler-icon-bg:       str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"), "#", "%23") !default;
$navbar-dark-toggler-border-color:  rgba($white, .1) !default;

$input-btn-padding-y-lg: 0.3rem;
$input-btn-padding-x-lg:    						1rem;
$input-btn-line-height-lg:  						1.5;
$font-size-lg:                $font-size-base * 1 !default;
$input-btn-font-size-lg:      $font-size-lg !default;


$btn-padding-y-lg:            $input-btn-padding-y-lg !default;
$btn-padding-x-lg:            $input-btn-padding-x-lg !default;
$btn-font-size-lg: 14px !default;
$btn-line-height-lg:          $input-btn-line-height-lg !default;


$btn-line-height-sm: 1 !default;

///buttons
$btn-font-family: AvenirLTPro-Medium !default;

// Badges

$badge-font-size:                   100% !default;
//$badge-font-weight:                 $font-weight-bold !default;
$badge-padding-y:                   .25em !default;
$badge-padding-x:                   .4em !default;
//$badge-border-radius:               $border-radius !default;

//$badge-transition:                  $btn-transition !default;
//$badge-focus-width:                 $input-btn-focus-width !default;


$badge-pill-padding-x:              .6em !default;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius:          10rem !default;
$badge-font-weight : 100;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:          .75rem !default;
$table-cell-padding-sm:       .3rem !default;

$table-color:                 #000 !default;
$table-bg:                    true !default;
$table-accent-bg:             #f5f6f6;
$table-hover-color:           $table-color !default;
$table-hover-bg:              rgba($black, .075) !default;
$table-active-bg:             $table-hover-bg !default;

$table-border-width:          $border-width !default;
$table-border-color:          transparent !default;

$table-head-bg:               $gray-200 !default;
$table-head-color:            $gray-700 !default;

$table-dark-color:            $white !default;
$table-dark-bg:               $gray-800 !default;
$table-dark-accent-bg:        #f5f6f6;
$table-dark-hover-color:      $table-dark-color !default;
$table-dark-hover-bg:         rgba($white, .075) !default;
$table-dark-border-color:     lighten($table-dark-bg, 7.5%) !default;
$table-dark-color:            $white !default;

$table-striped-order:         odd !default;

//$table-caption-color:         $text-muted !default;

$table-bg-level:              -9 !default;
$table-border-level:          -6 !default;


$modal-xl: 60% !default;
$modal-lg: 60% !default;
$modal-md: 90% !default;
$modal-sm: 100% !default;

$dropdown-border-color:             transparent !default;
$dropdown-box-shadow:               0 0 10px 0 rgba(0,0,0,.37);
$dropdown-link-hover-bg:            #fbe298;

$nav-tabs-border-color:             $gray-300 !default;
$nav-tabs-border-width:             $border-width !default;
$nav-tabs-border-radius:            $border-radius !default;
$nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color:        #000 !default;
$nav-tabs-link-active-bg:           #fbe298 !default;

$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;

