$gray-600: #6c757d !default;
.statsMain{
  margin-top: -8px!important;
  > .col-2{
    flex: 0 0 12.66667%;
    max-width: 12.66667%;
  }
  > .col-10{
    flex: 0 0 87.33333%;
    max-width: 87.33333%;
  }
  [class*="col-"]{
    padding-left: 10px!important;
    padding-right: 10px!important;
  }
  .col-10{
    padding-left: 18px!important;
    padding-right: 18px!important;
  }
  .col-8{
    padding-right: 10px!important;
  }

  &-nav{
    font-size: 1rem;
    li{
      padding: 9px 0px;
      &.active a{
        color: #0c0d10;
      }
    }
    a{
      color: #888888;
      font-family: 'AvenirLTPro-Black';
      font-weight: 800;
    }
  }
  h2{
    margin-bottom: -5px;
    font-size: 1.8rem;
    color: $gray-600;
    text-transform: uppercase;
  }
  &-number{

    .count{
      font-size: 2.5rem;
    }
  }

}

//bloc2
#criteria_bottles_or_glasses{
  .grouped{
    display: flex;
    .field{
      padding-right: 15px;
    }
  }
   input + label::before{
    width: 41px;
  }

}
.exportNav .btn-export{
  top: 12px!important;
  text-transform: uppercase;
  i{
    font-size: 1.2rem;
  }
  right: 177px;
}
.tableShowHide-main{
  top: 5px;
  position: absolute;
  right: 296px;
  text-transform: uppercase;
  i{
    font-size: 1.2rem;
  }
}
.tableShowHide-column{
  width: 101.8%;
  margin-top: 34px;
}
.js-stat-table{
  .b-actions{
    position: absolute;
    right: 0;
    top: 0;
    > div{
      align-items: baseline !important;
      display: flex;
      justify-content: flex-end !important;
      > *{
        width: 41px!important;
        font-size: 0;
      }
      button{
        margin-right: 5px;
        &:before{
          font-size: .8rem;
          font-family: 'Font Awesome 5 Pro';
          font-weight: 900;
          -webkit-font-smoothing: antialiased;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          line-height: 1;
          content: "\f0b0";
          display: block;
        }
      }
      a{
        &:before {
          font-size: .8rem;
          font-family: 'Font Awesome 5 Pro';
          font-weight: 900;
          -webkit-font-smoothing: antialiased;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          line-height: 1;
          content: "\f05e";
          display: block;
        }
      }
    }
  }
}
.statsBlockFirst{
  width: 103.8%;  margin-top: -11px;
}


.label-receiver{
  max-height: 482px;
  min-height: 482px;
  overflow: auto;
  padding: 15px;
  &-card{
    height: 95%;
  }
  ul{
    padding-left: 5px;
    list-style: none;
    li{
      font-size: 0.8rem;
      padding-bottom: 2px;
      line-height: 20px;
      border-bottom: 1px solid #ccd0d9;
      margin-bottom: 8px;
    }
  }
}