@font-face {
  font-family: 'AvenirLTPro-Oblique';
  src: url('../webfonts/fonts/AvenirLTPro-Oblique.woff2') format('woff2'),
  url('../webfonts/fonts/AvenirLTPro-Oblique.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'AvenirLTPro-BookOblique';
  src: url('../webfonts/fonts/AvenirLTPro-BookOblique.woff2') format('woff2'),
  url('../webfonts/fonts/AvenirLTPro-BookOblique.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'AvenirLTPro-BlackOblique';
  src: url('../webfonts/fonts/AvenirLTPro-BlackOblique.woff2') format('woff2'),
  url('../webfonts/fonts/AvenirLTPro-BlackOblique.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'AvenirLTPro-HeavyOblique';
  src: url('../webfonts/fonts/AvenirLTPro-HeavyOblique.woff2') format('woff2'),
  url('../webfonts/fonts/AvenirLTPro-HeavyOblique.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'AvenirLTPro-MediumOblique';
  src: url('../webfonts/fonts/AvenirLTPro-MediumOblique.woff2') format('woff2'),
  url('../webfonts/fonts/AvenirLTPro-MediumOblique.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'AvenirLTPro-Light';
  src: url('../webfonts/fonts/AvenirLTPro-Light.woff2') format('woff2'),
  url('../webfonts/fonts/AvenirLTPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirLTPro-LightOblique';
  src: url('../webfonts/fonts/AvenirLTPro-LightOblique.woff2') format('woff2'),
  url('../webfonts/fonts/AvenirLTPro-LightOblique.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'AvenirLTPro-Medium';
  src: url('../webfonts/fonts/AvenirLTPro-Medium.woff2') format('woff2'),
  url('../webfonts/fonts/AvenirLTPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirLTPro-Heavy';
  src: url('../webfonts/fonts/AvenirLTPro-Heavy.woff2') format('woff2'),
  url('../webfonts/fonts/AvenirLTPro-Heavy.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirLTPro-Roman';
  src: url('../webfonts/fonts/AvenirLTPro-Roman.woff2') format('woff2'),
  url('../webfonts/fonts/AvenirLTPro-Roman.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirLTPro-Book';
  src: url('../webfonts/fonts/AvenirLTPro-Book.woff2') format('woff2'),
  url('../webfonts/fonts/AvenirLTPro-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirLTPro-Black';
  src: url('../webfonts/fonts/AvenirLTPro-Black.woff2') format('woff2'),
  url('../webfonts/fonts/AvenirLTPro-Black.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

.AvenirLTPro-Black {
  font-family: 'AvenirLTPro-Black';
  font-weight: 800;
}

.AvenirLTPro-BlackOblique {
  font-family: 'AvenirLTPro-BlackOblique';
  font-weight: 800;
}

.font-Avenirbook {
  font-family: 'AvenirLTPro-Book';
  font-weight: normal;
  font-style: normal;
}

.font-AvenirLight {
  font-family: 'AvenirLTPro-Light';
  font-weight: 300;
  font-style: normal;
}

.font-AvenirMedium {
  font-family: 'AvenirLTPro-Medium';
  font-weight: 500;
  font-style: normal;
}

.font-AvenirblackOblique {
  font-family: 'AvenirLTPro-Oblique';
  font-weight: normal;
  font-style: italic;
}

.font-AvenirRoman {
  font-family: 'AvenirLTPro-Roman';
  font-weight: normal;
  font-style: normal;
}

.font-AvenirHeavy {
  font-family: 'AvenirLTPro-Heavy';
  font-weight: bold;
  font-style: normal;
}

.font-AvenirBold {
  font-family: 'AvenirLTPro-Black';
  font-weight: bold;
  font-style: normal;
}

body.fontsHack {
  font-family: 'AvenirLTPro-Medium';
  font-weight: 500;
  font-style: normal;

  .font-AvenirLight {
    font-family: 'AvenirLTPro-Medium';
    font-weight: 500;
    font-style: normal;
  }
}

$font-AvenirHeavy: 'AvenirLTPro-Heavy';
$font-AvenirBold: 'AvenirLTPro-Black';
$font-AvenirblackOblique: 'AvenirLTPro-BlackOblique';
$font-Avenirbook: 'AvenirLTPro-Book';
$font-AvenirLight: 'AvenirLTPro-Light';
$font-AvenirMedium: 'AvenirLTPro-Medium';
$font-AvenirOblique: 'AvenirLTPro-Oblique';
$font-AvenirRoman: 'AvenirLTPro-Roman';