@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value, $value);
    &:hover {
      @extend .btn-secondary;
    }
  }
}

.btn{
  &-outline-primary {
    color: #000;
  }
  &-export{
    right: 15px;
    @extend .font-AvenirMedium;
    @include media-breakpoint-up(xl) {
      position: absolute;
      top: 66px;
    }
  }
}