.panel-bottle {
  //main
  textarea {
    min-height: 100px;
  }

  //switch
  .switch {
    &-inverse {
      label {
        > span {
          float: right;
          margin-left: -131px;
          width: 177px;
        }
      }
    }
  }

  //btn
  .btn-entry {
    margin-right: 13px;
  }

  //btn d'actions
  &-actions {
    .btn {
      margin-bottom: 10px;
      max-width: 160px;
      margin-left: auto;
    }
  }

  //header
  &-title {
    @extend .font-AvenirHeavy;
    font-size: calculateRem(15px);
  }

  &-subTitle {
    @extend .font-AvenirMedium;
    font-size: calculateRem(14px);
  }

  &-price {
    @extend .AvenirLTPro-Black;
    font-size: calculateRem(39px);

    &-label {
      padding-left: 9px;
      font-size: calculateRem(20px);
    }
  }

  //stock
  &-stock {
    margin-top: 15px;

    &-label {
      padding-left: 9px;
      margin-bottom: 0;
      @extend .font-AvenirRoman;
      font-size: calculateRem(20px);
    }

    &-detail {
      @extend .font-AvenirRoman;
      font-size: calculateRem(14px);
    }

    &-count {
      @extend .AvenirLTPro-Black;
      font-size: calculateRem(39px);
    }
  }

  //History
  &-history {
    &-label {
      margin-top: 22px;
      margin-bottom: 4px;
      @extend .AvenirLTPro-Black;
    }

    &-list {
      line-height: 1rem;
      @extend .font-Avenirbook;
      font-size: calculateRem(12px);
      color: #818181;
    }

    &-more {
      @extend .font-AvenirMedium;
      color: #2b6882;
      margin-top: 4px;
      margin-left: 7px;
    }
  }

  //histoire
  &-what {
    &-label {
      margin-top: 30px;
      margin-bottom: 2px;
      @extend .AvenirLTPro-Black;
      font-size: calculateRem(16px);
    }

    &-content {
      min-height: 100px;
      border: 1px solid #828282;
      @extend .font-Avenirbook;
      font-size: calculateRem(14px);
      padding: calculateRem(12px);
    }
  }

  //information
  &-info {
    &-label {
      margin-bottom: 5px;
      @extend .AvenirLTPro-Black;
      font-size: calculateRem(16px);

      &:first-of-type {
        margin-top: 30px;
      }
    }

    &-content {
      margin-bottom: 19px;
      font-size: calculateRem(14px);
      @extend .font-Avenirbook;

      &-disabled {

      }
    }
  }

  //switch edit
  &-edit {
    &-switch {
      margin-left: 63px;
      margin-bottom: 0;
      margin-top: 31px;
    }
  }
}




